html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background: #000;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

.firebase-emulator-warning {
  display: none;
}
